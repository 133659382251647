<template>
    <div>
        <div class="w-100 h90 blc rowjcic">
            <div class="w1000 rowjbic ">
                <div v-for="(item,index) in bottomList" :key="index" class="cfff fs16">
                    {{item.name}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                bottomList: [
                    {
                        id: 1,
                        name: '安迩无忧企业管理平台© 2021-2025',
                        path: ''
                    },
                    {
                        id: 2,
                        name: '鲁ICP备05016296号-4',
                        path: ''
                    },
                    {
                        id: 3,
                        name: '鲁公网安备11010502041409号',
                        path: ''
                    }
                ]
            };
        },
    }

</script>
<style scoped>

</style>