import Vue from 'vue'
import axiosA from 'axios'
// import VueAxios from 'vue-axios'
Vue.use(axiosA)


export const axios = axiosA.create({
    baseURL: 'https://aner.vip/',
    //baseURL: 'http://192.168.1.46:8085/',
    method: 'POST',
    header: {
        'Content-Type': 'application/json;charset=utf-8'
    },
})
