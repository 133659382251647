import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import top from '@/components/top.vue';
import bottom from '@/components/bottom.vue'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/elementTheme/index.css';
import {axios} from './assets/axios'
 Vue.component('top',top)
 Vue.component('bottom',bottom)
Vue.use(ElementUI);
Vue.prototype.$axios=axios;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
