import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: ()=>import('@/pages/home.vue'),
    meta:{
      topStatus:1
    }
  },
  {
    path: '/home', //主页
    component: ()=>import('@/pages/home.vue'),
    meta:{
      topStatus:1
    }
  },
  {
    path: '/policy', //政策
    component: ()=>import('@/pages/policy.vue'),
    meta:{
      topStatus:2
    }
  },
  {
    path: '/policyInfo', //政策详情
    component: ()=>import('@/pages/policyInfo.vue'),
    meta:{
      topStatus:2
    }
  },
  {
    path: '/recruit',//招聘主页
    component: ()=>import('@/pages/recruit.vue'),
    meta:{
      topStatus:3
    }
  },
  {
    path: '/recruitInfo',//招聘详情
    component: ()=>import('@/pages/recruitInfo.vue'),
    meta:{
      topStatus:3
    }
  },
  {
    path: '/about', //关于我们
    component: ()=>import('@/pages/about.vue'),
    meta:{
      topStatus:5
    }
  },
  {
    path: '/personalCenter', //个人中心
    component: ()=>import('@/pages/personalCenter.vue'),
    meta:{
      topStatus:''
    }
  },
  {
    path: '/settled', //企业入驻
    component: ()=>import('@/pages/settled.vue'),
    meta:{
      topStatus:4
    }
  },
  {
    path: '/appEntry', //app入驻
    component: ()=>import('@/pages/appEntry.vue'),
    meta:{
      topStatus:4
    }
  },
  {
    path: '/erpEntry', //erp入驻
    component: ()=>import('@/pages/erpEntry.vue'),
    meta:{
      topStatus:4
    }
  },
  {
    path: '/erpappEntry', //erp和app入驻
    component: ()=>import('@/pages/erpappEntry.vue'),
    meta:{
      topStatus:4
    }
  },
  {
    path: '/policyShop', //erp和app入驻
    component: ()=>import('@/pages/policyShop.vue'),
    meta:{
      topStatus:4
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

 

export default router
