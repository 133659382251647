<template>
    <div>
        <div class="h100 w-100 bcfff rowjcic">
            <div class=" rowjbic" style="width: 1000px;">
                <div class="w160 h55"><img src="@/assets/img/logo.png"></div>
                <div class="borlc w180 h40 lc br100 tc lh40 fs16" @click="xiazai()">
                    下载安迩健康APP
                </div>
            </div>
        </div>
        <div class="h60 w-100  rowjcic blc">
            <div class="w1000 rowjbic">
             <div @click="navigationJump(item)" :class="navigationBarIndex==item.id?'active':''" class="w120 tc lh60 h60 cfff"  v-for="(item,index) in navigationBar" :key="index">
                {{item.name}}
             </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'top',
        data() {
            return {
                navigationBarIndex: 1,
                navigationBar:[
                {
                    id:1,
                    name:"首页",
                    path:"/"
                },
                {
                    id:2,
                    name:"政策信息",
                    path:"/policy"
                },
                {
                    id:3,
                    name:"无忧招聘",
                    path:"/recruit"
                },
                {
                    id:4,
                    name:"入驻服务",
                    path:"/policyShop"
                },
                {
                    id:5,
                    name:"关于我们",
                    path:"/about"
                }
            ]

            };
        },
        mounted() {
            this.navigationBarIndex=this.$route.meta.topStatus
           
        },
        methods: {
            navigationJump(item){
                this.navigationBarIndex=item.id
                this.$router.push({
                    path:item.path
                })
            },
            xiazai(){
                location.href = "http://www.myaner.vip/安迩健康.apk";
            }
        },
       
    }
</script>
<style scoped>
    .active{
        background-color: #B70113 ;
    }
</style>